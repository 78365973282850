import { Controller, useForm } from "react-hook-form";
import TextEditor from "../utils/text-editor";
import ReactQuill from "react-quill";
import { formats, modules } from "../utils/editor-tool-options";
import { addAgrementContract,getAgrementContract} from "../services/services";
import { toast } from "react-toastify";
import { useEffect } from "react";

const Aggrement = () => {
    const { control, handleSubmit, formState: { errors }, watch, setValue, getValues } = useForm()

    const onSubmit = (data) => {
        addAgrementContract(data).then(res => {
            if (res.status == 201) {
                toast.success('Agreement & contract added successfully.')
                getAgreement()
            }
        })
    }

    const getAgreement = () => {
        getAgrementContract().then(res => {
            if (res.status == 200) {
                if (res?.data?.data?.length !== 0) {
                    setValue('content', res?.data?.data?.content)
                }
            }
        })
    }

    useEffect(() => {
        getAgreement()
    }, []);
    return (
        <>
            <main className="content">
                <div>
                    <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                        <i className="ri-menu-line ri-xl"></i>
                    </a>
                    <div className="container-fluid allCards-new">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="clearfix mt-3 mb-4">
                                    <div className="float-start">
                                        <h2 className="text-contact">Agreement & Contract</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div id="summernote">
                                            <form className="form-design" onSubmit={handleSubmit(onSubmit)}>
                                                <Controller
                                                    name="content"
                                                    rules={{ required: "Field is required." }}
                                                    control={control}
                                                    render={(({ field: { onChange, value }, fieldState: { error } }) =>
                                                        <>
                                                            <TextEditor />
                                                            <ReactQuill
                                                                theme="snow"
                                                                value={value}
                                                                onChange={(val) => val === "<p><br></p>" ? onChange('') : onChange(val)}
                                                                placeholder={"Enter Content."}
                                                                modules={modules}
                                                                formats={formats}
                                                            />
                                                            {error?.message && <span className="err-msg">{error.message}</span>}
                                                        </>
                                                    )} />

                                                <div className="col-md-2"><button type="submit" className="btn btn-linear btn-block w-100 mt-3">Submit</button></div>

                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Aggrement