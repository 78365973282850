import React from 'react'
import NoData from "../../assets/images/No_data-rafiki.svg"
import { FaCheck, FaTimes } from 'react-icons/fa';
import { toast } from "react-toastify";
import { updateStatusBank } from "../../services/services"
import { ShimmerText } from "react-shimmer-effects";

const ModelBankDetrails = (props) => {
        const { bankDetail,onPassDataSelfi,loading } = props


        const handleClickStatus = (bankId,statusValue)=>{
                let daata={
                        bankId:bankId,
                        status:statusValue,
                        type:"bankDetail"
                    }
                    updateStatusBank(daata).then(res => {
                        if (res?.status == 200) {
                                toast.success(res.data.message, {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 3000,
                                    });
                                    onPassDataSelfi(res?.status)
                            
                        }
                    }).catch((err) => {
                        if (err.response.status == 401 || err.response.status == 400) {
                                toast.error(err.response.data.message, {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 3000,
                                    });              
                        }
                       
                    })
        }


        return (
                <div><form className="form-login n  n">
                        {bankDetail ? <div className="row">
                                <div className="col-md-4">
                                        <div className="mb-4">
                                                <label className="form-label">Name</label>
                                                {loading?(<ShimmerText line={1} />):(<p className="h5 name-next-line">{bankDetail?.firstName ? bankDetail?.firstName : "NIL"}</p>)}
                                                
                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4">
                                                <label className="form-label ">Last Name</label>
                                                {loading?(<ShimmerText line={1} />):(<p className="h5 name-next-line">{bankDetail?.lastName ? bankDetail?.lastName : "NIL"}</p>)}
                                                

                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4">
                                                <label className="form-label">Email</label>
                                                {loading?(<ShimmerText line={1} />):(<p className="h5 name-next-line">{bankDetail?.email ? bankDetail?.email : "NIL"}</p>)}
                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4">
                                                <label className="form-label">Phone</label>
                                                {loading?(<ShimmerText line={1} />):<p className="h5">{bankDetail?.phone ? bankDetail?.phone : "NIL"}</p>}                                              
                                        </div>
                                </div>

                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">City</label>
                                                {loading?(<ShimmerText line={1} />):<p className="h5">{bankDetail?.city ? bankDetail?.city : "NIL"}</p>}
                                                

                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">AddressLine 1</label>
                                                {loading?(<ShimmerText line={1} />):( <p className="h5">{bankDetail?.addressLine1 ? bankDetail?.addressLine1 : "NIL"}</p>)}
                                               

                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">AddressLine 2</label>
                                                {loading?(<ShimmerText line={1} />):(<p className="h5">{bankDetail?.addressLine2 ? bankDetail?.addressLine2 : "NIL"}</p>)}
                                                

                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Account Type</label>
                                                {loading?(<ShimmerText line={1} />):(<p className="h5">{bankDetail?.accountType ? bankDetail?.accountType : "NIL"}</p>)}
                                                

                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Bank Country</label>
                                                {loading?(<ShimmerText line={1} />):(<p className="h5">{bankDetail?.bankCountry ? bankDetail?.bankCountry : "NIL"}</p>)}
                                                

                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Currency</label>
                                                {loading?(<ShimmerText line={1} />):( <p className="h5">{bankDetail?.currency ? bankDetail?.currency : "NIL"}</p>)}
                                               

                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Personal ID Type</label>
                                                {loading?(<ShimmerText line={1} />):(<p className="h5">{bankDetail?.personalIdType ? bankDetail?.personalIdType : "NIL"}</p>)}
                                                

                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Personal ID</label>
                                                {loading?(<ShimmerText line={1} />):(<p className="h5">{bankDetail?.personalId ? bankDetail?.personalId : "NIL"}</p>)}
                                                
                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Postal Code</label>
                                                {loading?(<ShimmerText line={1} />):(<p className="h5">{bankDetail?.postalCode ? bankDetail?.postalCode : "NIL"}</p>)}
                                                
                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Date Of Birth</label>
                                                <p className="h5">{bankDetail?.date_of_birth ? bankDetail?.date_of_birth.split('T')[0] : "NIL"}</p>
                                        </div>
                                </div>

                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Bank Location</label>
                                                <p className="h5">{bankDetail?.bankLocation ? bankDetail?.bankLocation : "NIL"}</p>
                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Account Name</label>
                                                <p className="h5 text-break">{bankDetail?.accountName ? bankDetail?.accountName : "NIL"}</p>
                                        </div>
                                </div>

                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Account Number</label>
                                                <p className="h5 text-break">{bankDetail?.accountNumber ? bankDetail?.accountNumber : "NIL"}</p>
                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Bank Code</label>
                                                <p className="h5 text-break">{bankDetail?.bankCode ? bankDetail?.bankCode : "NIL"}</p>
                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Bank Name</label>
                                                <p className="h5 text-break">{bankDetail?.bankName ? bankDetail?.bankName : "NIL"}</p>
                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Swift Code</label>
                                                <p className="h5 text-break">{bankDetail?.swiftCode ? bankDetail?.swiftCode : "NIL"}</p>
                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Created Date</label>
                                                <p className="h5">{bankDetail?.createdAt ? bankDetail?.createdAt.split('T')[0] : "NIL"}</p>
                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Status</label>
                                                <p className="h5">{(bankDetail.status === "Pending" || bankDetail.status === "In review") ? (<p className="h6" style={{ color: "red" }}>Pending</p>) : (bankDetail.status === "Approved" ? <p style={{ color: "green" }}>{bankDetail.status}</p> : <p style={{ color: "orange" }}>{bankDetail.status}</p>)}</p>
                                        </div>
                                </div>
                                <div className="col-md-4"> <div className="mb-4" >
                                        <label className="form-label">Action</label>
                                        <div>
                                                <button className="btn btn-success btn-sm  common-approve-rej" type="button" onClick={() => handleClickStatus(bankDetail._id, 'Approved')}> <FaCheck /> Approve</button>
                                                <button className="btn btn-light btn-sm ms-2" type="button" onClick={() => handleClickStatus(bankDetail._id, 'Rejected')}>
                                                        <FaTimes /> Reject
                                                </button>
                                        </div>
                                </div></div>
                        </div> : <img src={NoData} alt="" className="object-fit-cover d-block mx-auto" width={500} height={500} />}


                </form ></div >
        )
}


export default ModelBankDetrails