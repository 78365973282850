import React, { useEffect, useState } from 'react'
import { RiEyeFill } from 'react-icons/ri'
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getCryptoPayout,updateStatusBank } from "../../services/services"
import ReactPaginate from 'react-paginate'
import ApprovedCryptoPayout from './ApprovedCryptoPayout';

const CryptoPayment = () => {
    const [bankDetailsList,setBankDetailsList] = useState([]);
    const [paginated, setPaginated] = useState([])
        const [page, setPage] = useState(0)
        const [offset, setOffset] = useState(0)
        const [itemsPerPage, setItemsPerPage] = useState(10)
        const [pageCount, setPageCount] = useState(0)
        const [search, setSearch] = useState('')
        const [showApprovedRejectModel, setShowApprovedRejectModel] = useState(false)
    const [bankId, setBankId] = useState('')
    const [statusValue, setStatusValue] = useState('')
    
        const GetBank = () => {
            getCryptoPayout().then(res => {
                console.log(res)
                 if(res.status==200){
                    setBankDetailsList(res.data.data.crypto)
                    setPageCount(res.data.data.crypto.length / itemsPerPage)
                 }
            }).catch((err) => {
                console.log(err.response.status);
                if (err.response.status == 401) {
                    toast.error(err.response.data.message)
                }
                if (err.response.status == 400) {
                    toast.error(err.response.data.message)
                }
            })
        }
    
        const paginationHandler = () => {
            const endOffset = offset + itemsPerPage;
            let paginated = []
            if (search.length > 0) {
                let searchArr = bankDetailsList.filter((item) => item.userName.toLowerCase().includes(search.toLowerCase()))
                setPageCount(Math.ceil(searchArr.length / itemsPerPage))
                paginated = searchArr.slice(offset, endOffset)
            }
            else {
                setPageCount(Math.ceil(bankDetailsList.length / itemsPerPage))
                paginated = bankDetailsList.slice(offset, endOffset)
            }
            setPaginated(paginated)
        }
        const handleSearch = (e) => {
            setSearch(e.target.value)
            setOffset(0)
            setPage(0)
    
        }
        const handlePageClick = (e) => {
            setPage(e.selected)
            const newOffset = (e.selected * itemsPerPage);
            setOffset(newOffset)
        }
        useEffect(() => {
            paginationHandler()
        }, [bankDetailsList, offset, search]);
    
    
        useEffect(()=>{
            GetBank()
        },[])
    
        const handleClose = () => {
            setShowApprovedRejectModel(false)
        }
        const handleClickStatus = (id, status) => {
            setStatusValue(status)
            setBankId(id)
            setShowApprovedRejectModel(true)
        }
    
        const handleStatusUpdate =()=>{
            let daata={
                bankId:bankId,
                status:statusValue,
                type:"cryptoPayout"
            }
            updateStatusBank(daata).then(res => {
                if (res?.status == 200) {
                    toast.success(res.data.message)
                    GetBank()
                    
                }
            }).catch((err) => {
                if (err.response.status == 401 || err.response.status == 400) {
                    toast.error(err.response.data.message)
  
                }
               
            })
        }

    
        return (
            <>
                <main className="content">
                    <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                        <i className="ri-menu-line ri-xl"></i>
                    </a>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="clearfix mt-3 mb-4">
                                    <div className="float-start">
                                        <h2 className="text-contact">Manage Crypto Payout</h2>
                                    </div>
                                    <div className="float-end">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb contact-bread">
                                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Crypto Payout</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showApprovedRejectModel && <ApprovedCryptoPayout showApprovedRejectModel={showApprovedRejectModel}  statusValue={statusValue} handleClose={handleClose} handleStatusUpdate={handleStatusUpdate}/>}
                        <div className="row">
                            <div className="col-12">
                                <div className="card border-0 manage-usr-text mb-5">
                                    <div className="card-body">
                                        <div className="search-user"><label>Search:<input type="search" className="search-input" placeholder="" aria-controls="table" onChange={handleSearch} /></label></div>
                                        <div className="table-responsive">
                                            <table className="table table-first text-nowrap" id="table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr no.</th>
                                                        <th>User Name</th>                                             
                                                        <th>Email</th>  
                                                        <th>Address</th>   
                                                        <th>Status</th>                                        
                                                        <th className='text-center'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {paginated && paginated.map((bank,i)=>
                                                <tr key={i}>
                                                <td>{((page + 1) * itemsPerPage - itemsPerPage) + i + 1}</td>
                                                <td> {bank.userName}</td>
                                                <td> {bank.email}</td>
                                                <td> {bank.virstial_address_text.length>0?bank.virstial_address_text:"No Address"}</td>                                               
                                                <td>{(bank.status === "Pending" || bank.status === "In review" ) ? (<p style={{ color: "red" }}>Pending</p>) : (bank.status === "Approved" ? <p style={{ color: "green" }}>{bank.status}</p> : <p style={{ color: "orange" }}>{bank.status}</p>)}</td>
                                                <td align="center">
                                                <Link to={`/cryptoPayoutDetail/${bank._id}`} className="me-2">
                                                <button className="btn btn-light" type="button"><RiEyeFill /></button>
                                                </Link>
                                                <button className="btn btn-success btn-sm  common-approve-rej" type="button" onClick={() => handleClickStatus(bank._id, 'Approved')}> <FaCheck /> Approve</button>
                                                            <button className="btn btn-light btn-sm ms-2" type="button"
                                                             onClick={() => handleClickStatus(bank._id, 'Rejected')}
                                                            >
                                                                <FaTimes /> Reject
                                                            </button>
                                                </td>
                                            </tr>
                                                )}
                                                    
                                                    
                                                </tbody>
                                            </table>
                                            {bankDetailsList.length == 0 && <span className="no-data"><p>No Data Found</p></span>}
                                        </div>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {bankDetailsList.length > 10 &&
                        <ReactPaginate
                            breakLabel="..."
                            breakClassName={'break'}
                            nextLabel="next >"
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            onPageChange={handlePageClick}
                            forcePage={page}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="< previous"
    
                        />
                    }
    
                </main>
    
            </>
        );
}




export default CryptoPayment