import React from 'react'
import { GetUrl } from '../../config/GetUrl'
import WrittingPng from '../../assets/images/writings.png'
import NoData from "../../assets/images/No_data-rafiki.svg"
import { FaCheck, FaTimes } from 'react-icons/fa';
import { toast } from "react-toastify";
import { updateStatusBank } from "../../services/services"

const ModelCryptoPayout = (props) => {
    const { cryptoPayout,onPassDataSelfi } = props

    const handleClickStatus = (cryptoId,statusValue)=>{
        let daata={
            bankId:cryptoId,
            status:statusValue,
            type:"cryptoPayout"
        }
        updateStatusBank(daata).then(res => {
            if (res?.status == 200) {
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                });
                onPassDataSelfi(res?.status)
            }
        }).catch((err) => {
            if (err.response.status == 401 || err.response.status == 400) {
                toast.error(err.response.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                });     
            }
           
        })
    }

    return (
        <div><form className="form-login n  n">
            {
                cryptoPayout?  <div className="row align-items-center">
                <div className="col-md-4">
                    <div className="mb-4">
                        <label className="form-label">Email</label>
                        <p className="h5 name-next-line">{cryptoPayout?.email ? cryptoPayout?.email : "NIL"}</p>

                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-4">
                        <label className="form-label">User Name</label>
                        <p className="h5 name-next-line">{cryptoPayout?.email ? cryptoPayout?.email : "NIL"}</p>

                    </div>
                </div>
                <div className="col-md-4"> <div className="mb-">
                    <label className="form-label">Created Date</label>
                    <p className="h5">{cryptoPayout?.createdAt ? cryptoPayout?.createdAt.split('T')[0] : "NIL"}</p>

                </div></div>

                <div className="col-md-5"> <div className="mb-4">
                    <label className="form-label">Document (Upload Qr Code)</label>
                   {cryptoPayout?.upload_Qr_code?<div class="card">
                        <div class="card-body">
                            <img src={WrittingPng} class="card-img-top" alt="images" style={{ objectFit: "contain", height: "60px" }} />
                        </div>

                        <a href={GetUrl.IMAGE_URL + cryptoPayout.upload_Qr_code} target="_blank" class="stretched-link"></a>
                    </div>:<div className="card">
                        <div className="card-body nil-box">

                        <p className="h5">NIL</p>

                        </div>
                    </div>} 

                </div></div>
                
               <div className='col-md-2'>
                   <h5 className='text-center '>OR</h5>
               </div>
               <div className="col-md-5"><div className="mb-4">
                    <label className="form-label">Wallet Address</label>

                    <div className="card">
                        <div className="card-body nil-box">

                        <p className="h5">{cryptoPayout?.virstial_address_text ? cryptoPayout?.virstial_address_text : "NIL"}</p>

                        </div>
                    </div>
                   

                </div></div>
               
                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">Status</label>
                                                <p className="h5">{(cryptoPayout.status === "Pending" || cryptoPayout.status === "In review") ? (<p className="h6" style={{ color: "red" }}>Pending</p>) : (cryptoPayout.status === "Approved" ? <p style={{ color: "green" }}>{cryptoPayout.status}</p> : <p style={{ color: "orange" }}>{cryptoPayout.status}</p>)}</p>
                                        </div>
                                </div>
                                <div className="col-md-4"> <div className="mb-4" >
                                        <label className="form-label">Action</label>
                                        <div>
                                                <button className="btn btn-success btn-sm  common-approve-rej" type="button" onClick={() => handleClickStatus(cryptoPayout._id, 'Approved')}> <FaCheck /> Approve</button>
                                                <button className="btn btn-light btn-sm ms-2" type="button"
                                                    onClick={() => handleClickStatus(cryptoPayout._id, 'Rejected')}
                                                >
                                                        <FaTimes /> Reject
                                                </button>
                                        </div>
                                </div></div>

            </div>:<img src={NoData} alt="" className="object-fit-cover d-block mx-auto" width={500} height={500} />
            }
          



        </form></div>
    )
}




export default ModelCryptoPayout