import { useEffect, useState } from 'react';
import { approveModelRegister, rejectModelRegister, rejectModel, retrieveUsers } from '../../services/services';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RiCheckLine, RiCloseFill, RiEyeFill } from 'react-icons/ri';
import { FaCheck, FaTimes } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import ViewModelDetails from './ViewModelDetails';

const UnApprovedModels = () => {
  const [show, setShow] = useState(false);
  const [models, setModels] = useState([]);
  const [paginated, setPaginated] = useState([]);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const [modelId, setModelId] = useState('');

  const Models = () => {
    retrieveUsers()
      .then((res) => {
        if (res.status == 200) {
          let check = res.data.data.filter((user) => user.role == 'model');
          setModels(res.data.data.filter((user) => user.role == 'model' && user.status == 'Pending'));
          setPageCount(
            Math.ceil(
              res.data.data.filter((user) => user.role == 'model' && !user.is_approved && !user.is_rejected).length /
                itemsPerPage
            )
          );
        }
      })
      .catch((err) => {
        console.log(err.response.status);
        if (err.response.status == 401) {
          toast.error(err.response.data.message);
          //  localStorage .clear()
          // navigate('/')
        }
        if (err.response.status == 400) {
          toast.error(err.response.data.message);
          //  localStorage .clear()
          // navigate('/')
        }
      });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(0);
    setPage(0);
  };

  const paginationHandler = () => {
    const endOffset = offset + itemsPerPage;
    let paginated = [];
    if (search.length > 0) {
      let searchArr = models.filter(
        (item) =>
          item.username.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase())
      );
      setPageCount(Math.ceil(searchArr.length / itemsPerPage));
      paginated = searchArr.slice(offset, endOffset);
    } else {
      setPageCount(Math.ceil(models.length / itemsPerPage));
      paginated = models.slice(offset, endOffset);
    }
    setPaginated(paginated);
  };

  const handlePageClick = (e) => {
    setPage(e.selected);
    const newOffset = e.selected * itemsPerPage;
    setOffset(newOffset);
  };
  const handleApprove = (id) => {
    let data = {
      modelId: id,
    };
    approveModelRegister(data).then((res) => {
      if (res.status === 200) {
        toast.success(res?.data?.message);
        Models();
      }
    });
  };
  const handleReject = (id) => {
    let data = {
      modelId: id,
    };
    rejectModelRegister(data).then((res) => {
      if (res.status === 200) {
        toast.success(res?.data?.message);
        Models();
      }
    });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleModelDetail = (id) => {
    setModelId(id);
    setShow(true);
  };

  useEffect(() => {
    Models();
  }, []);
  useEffect(() => {
    paginationHandler();
  }, [models, offset, search]);
  return (
    <>
      <main className='content'>
        <a id='btn-toggle' href='#' className='sidebar-toggler break-point-sm'>
          <i className='ri-menu-line ri-xl'></i>
        </a>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='clearfix mt-3 mb-4'>
                <div className='float-start'>
                  <h2 className='text-contact'>Requested Models</h2>
                </div>
                <div className='float-end'>
                  <nav aria-label='breadcrumb'>
                    <ol className='breadcrumb contact-bread'>
                      <li className='breadcrumb-item'>
                        <a href='#'>Home</a>
                      </li>
                      <li className='breadcrumb-item'>
                        <a href='#'>Manage Models</a>
                      </li>
                      <li className='breadcrumb-item active' aria-current='page'>
                        Registered Models
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          {show && <ViewModelDetails show={show} handleClose={handleClose} modelId={modelId} />}
          <div className='row'>
            <div className='col-12'>
              <div className='card border-0 manage-usr-text mb-5'>
                <div className='card-body'>
                  <div className='search-user'>
                    <label>
                      Search:
                      <input
                        type='search'
                        className='search-input'
                        placeholder=''
                        aria-controls='table'
                        onChange={handleSearch}
                      />
                    </label>
                  </div>
                  <div className='table-responsive'>
                    <table className='table table-first text-nowrap' id='table'>
                      <thead>
                        <tr>
                          <th>Sr no.</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Gender</th>
                          <th>Region</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginated &&
                          paginated.map((model, i) => (
                            <tr>
                              <td>{(page + 1) * itemsPerPage - itemsPerPage + i + 1}</td>
                              <td>
                                {/* <img src={process.env.REACT_APP_IMAGE_URI + model.avatar} alt="image" width="40" height="40" className="rounded-circle me-3" /> */}
                                {model?.username?.length > 10
                                  ? model.username.substring(0, 10) + '...'
                                  : model.username}
                              </td>
                              <td>{model.email}</td>
                              <td>{model.gender}</td>
                              <td>{model.region}</td>
                              <td>{model.status && <p style={{ color: 'red' }}>{model.status}</p>}</td>
                              <td>
                                <button
                                  className='btn btn-light me-2'
                                  type='button'
                                  onClick={() => handleModelDetail(model._id)}
                                >
                                  <RiEyeFill />
                                </button>
                                <button
                                  className='btn btn-success btn-sm  common-approve-rej'
                                  type='button'
                                  onClick={() => handleApprove(model._id)}
                                >
                                  <FaCheck /> Approve
                                </button>
                                <button
                                  className='btn btn-light btn-sm ms-2'
                                  type='button'
                                  onClick={() => handleReject(model._id)}
                                >
                                  <FaTimes /> Reject
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {paginated.length == 0 && (
                      <span className='no-data'>
                        <p>No Data Found</p>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {models.length > 10 && (
          <ReactPaginate
            breakLabel='...'
            breakClassName={'break'}
            nextLabel='next >'
            containerClassName={'pagination'}
            activeClassName={'active'}
            onPageChange={handlePageClick}
            forcePage={page}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel='< previous'
          />
        )}
      </main>
    </>
  );
};

export default UnApprovedModels;
