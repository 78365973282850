import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Row, Col } from "react-bootstrap";
import { RejectTrans } from "../../services/services"
import Swal from "sweetalert2"
import { toast } from "react-toastify"
const buttonWidth = {
    width: "100%",
};

//modelId={modelId} ModelTransaction={ModelTransaction}
const RejectedReasonPopup = ({ rejectPopupShow, handleClose, modelId, Users }) => {
    const [reasons, setReasons] = useState([""]);
    const handleAddReason = () => {
        if (reasons.length < 4) {
            setReasons([...reasons, ""]);
        }
    };
    const handleRemoveReason = (index) => {
        setReasons(reasons.filter((_, i) => i !== index));
    };
    const handleReasonChange = (index, value) => {
        const updatedReasons = [...reasons];
        updatedReasons[index] = value;
        setReasons(updatedReasons);
    };
    const handleSubmit = (e) => {
        e.preventDefault();

           if(reasons[0]?.length===0){
            toast.error("Reason is required")
            return;
           }
          

        const data = {
            userId: modelId,
            reasons: reasons
        }
        RejectTrans(data).then(res => {
            if (res.status == 200) {
                Swal.fire('', "Transaction Rejected successfully.", 'success')
                handleClose()
                Users()
                setReasons([""])
            }
        }).catch(err => {
            if (err.response?.status == 401 || err?.response?.status == 400) {
                toast.error(err?.response?.data?.message)
                localStorage.clear()

            }
        })

    };

    return (
        <Modal show={rejectPopupShow} onHide={handleClose} centered size="md">
            <Modal.Header closeButton className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className="h5">Add Rejection Reason</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className="p-4">
                <form onSubmit={handleSubmit} className="form-login">
                    {reasons.map((reason, index) => (
                        <Row key={index} className="mb-3 align-items-center">
                            <Col xs={reasons.length === 1 ? 12 : 8}>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Rejection Reason"
                                    value={reason}
                                    onChange={(e) => handleReasonChange(index, e.target.value)}
                                    maxLength={50}
                                    className="py-3"
                                />
                            </Col>
                            <Col xs={2}>
                                {reasons.length > 1 && <Button
                                    variant="danger"
                                    onClick={() => handleRemoveReason(index)}
                                    disabled={reasons.length === 1} // Keep at least one reason
                                >
                                    Delete
                                </Button>}

                            </Col>
                        </Row>
                    ))}
                    <div className="row mt-4">
                    <div className="col-5">
                            <Button
                                variant="primary"
                                onClick={handleAddReason}
                                disabled={reasons.length >= 4}
                                className="mb-3 w-100 btn btn-invite next fw-bolder"
                            >
                                Add Reason
                            </Button>
                        </div>
                       
                        <div className="col-2"></div>
                        <div className="col-5">
                            <button
                                type="submit"
                                className="btn btn-invite next fw-bolder update-class w-100"
                                style={buttonWidth}
                            >
                                Submit
                            </button>
                        </div>


                    </div>

                </form>
            </Modal.Body>
        </Modal>
    );
};

export default RejectedReasonPopup;
