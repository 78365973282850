import React, { useEffect, useState } from 'react'
import { RiDeleteBin2Fill,RiEyeFill } from 'react-icons/ri'

import { Link } from 'react-router-dom'
import { getUserSupport,updateSupports,deletionSupports } from "../../services/services"
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate"



const UserSupport = () => {
    const [userData, setUserData] = useState([]);
    const [paginated, setPaginated] = useState([])
    const [page, setPage] = useState(0)
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState('')

    const GetUserSupportData = () => {
        getUserSupport().then(res => {
            if (res.status == 200) {
                setUserData(res?.data?.data)
            }
        }).catch((err) => {
            if (err.response.status == 401) {
                toast.error(err.response.data.message)
            }
            if (err.response.status == 400) {
                toast.error(err.response.data.message)
            }
        })
    }

    const paginationHandler = () => {
        const endOffset = offset + itemsPerPage;
        let paginated = []
        if (search.length > 0) {
            let searchArr = userData.filter((item) => item?.user?.username?.toLowerCase().includes(search.toLowerCase()) || item?.user?.email?.toLowerCase().includes(search.toLowerCase()))
            setPageCount(Math.ceil(searchArr.length / itemsPerPage))
            paginated = searchArr.slice(offset, endOffset)
        }
        else {
            setPageCount(Math.ceil(userData.length / itemsPerPage))
            paginated = userData.slice(offset, endOffset)
        }
        setPaginated(paginated)
    }

    const handlePageClick = (e) => {
        setPage(e.selected)
        const newOffset = (e.selected * itemsPerPage);
        setOffset(newOffset)
    }
    const handleSearch = (e) => {
        setSearch(e.target.value)
        setOffset(0)
        setPage(0)

    }
    const handleUpdation = (id) =>{
        updateSupports(id).then(res => {
            if (res.status == 200) {
                GetUserSupportData()
                toast.success(res?.data?.message)
            }
        }).catch((err) => {
            if (err.response.status == 401) {
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
            if (err.response.status == 400) {
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
        })
    }

    const handleDeletion = (id) =>{
        deletionSupports(id).then(res => {
            if (res.status == 200) {
                GetUserSupportData()
                toast.success(res?.data?.message)
            }
        }).catch((err) => {
            if (err.response.status == 401) {
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
            if (err.response.status == 400) {
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
        })
    }

    useEffect(() => {
        GetUserSupportData()
    }, [])
    useEffect(() => {
        paginationHandler()
    }, [userData, offset,search])
    return (
        <>
            <main className="content">
                <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                    <i className="ri-menu-line ri-xl"></i>
                </a>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="clearfix mt-3 mb-4">
                                <div className="float-start">
                                    <h2 className="text-contact">Manage User Support</h2>
                                </div>
                                <div className="float-end">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb contact-bread">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">User Support</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card border-0 manage-usr-text mb-5">
                                <div className="card-body">
                                    <div className="search-user"><label>Search:<input type="search" className="search-input" placeholder="" aria-controls="table"  onChange={handleSearch}/></label></div>
                                    <div className="table-responsive">
                                        <table className="table table-first text-nowrap" id="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr no.</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Description</th>
                                                    <th>Status</th>
                                                    <th className=''>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginated && paginated.map((user, i) =>
                                                    <tr key={i}>
                                                        <td>{((page + 1) * itemsPerPage - itemsPerPage) + i + 1}</td>
                                                        <td>{user?.user?.username.length>10?user?.user?.username.substring(0,15)+'...':user?.user?.username}</td>
                                                        <td>{user?.email}</td>
                                                        <td>{user?.question?.length>10?user?.question.substring(0,15)+'...':user?.question}</td>
                                                        {/* <td>{user?.status}</td> */}
                                                        <td>{user.status=='Pending' ? <p style={{ color: "red" }}>{user.status}</p> : <p style={{ color: "green" }}>{user.status}</p>}</td>
                                                        <td align="center" className=''>
                                                            <span className='d-flex align-items-center'>
                                                            <Link to={`/manage-usersupport-detail/${user._id}`} className="me-2">
                                                            <button className="btn btn-light" type="button" ><RiEyeFill /></button>
                                                            </Link>
                                                           
                                                                <button className="btn btn-light d-flex align-items-center  ms-3" type="button" onClick={() => handleDeletion(user._id)}><RiDeleteBin2Fill /> <span className='ms-1'>Delete</span></button>
                                                           {user?.status=='Pending' && <button className="btn  ms-3 btn-light d-flex align-items-center" type="button" onClick={() => handleUpdation(user._id)}> <span className='ms-1'>Mark As Resolved</span></button>} 
                                                            </span>
                                                            
                                                        </td>
                                                    </tr>
                                                )}

                                               
                                            </tbody>
                                        </table>
                                        {paginated.length == 0 && <span className="no-data"><p>No Data Found</p></span>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {userData?.length > 10 &&
                    <ReactPaginate
                        breakLabel="..."
                        breakClassName={'break'}
                        nextLabel="next >"
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        onPageChange={handlePageClick}
                        forcePage={page}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"

                    />
                }
            </main>

        </>
    );
}

export default UserSupport